import Julius from "../components/ChatBot/Julius";
import "./Style.scss";
import Bg from "../components/assets/FOTOS/FOTO3.png";
import {
  Box,
  Button,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { ArrowBack, WhatsApp } from "@material-ui/icons";
import Footer from "../components/layout/Footer";
const useStyles = makeStyles((theme) => ({
  Back: {
    backgroundColor: "#0e2a3d",
    width: "100%",
    display: "flex",
    overflow: "none",
    minHeight: "100vh",
  },
  Form: {
    [theme.breakpoints.down("lg")]: {
      width: "40%",
      boxShadow: "0px 0px 29px -1px rgba(0,0,0,0.75)",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      boxShadow: "0px 0px 29px -1px rgba(0,0,0,0.75)",
    },
  },
  FormBackground: {
    [theme.breakpoints.down("lg")]: {
      width: "60%",
      boxShadow: "0px 0px 29px -1px rgba(0,0,0,0.75)",
      backgroundColor: "#0e2a3d",
      backgroundImage: `url(${Bg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundColor: "#0e2a3d",
    },
    [theme.breakpoints.down("xs")]: {
      display:"none"
    },
  },
}));
export default function Contact(props) {
  const classes = useStyles();
  const ChangePage = (page) => {
    props.ChangePage(page);
  };
  const ActiveServices=(Service)=>{
    props.ActiveServices(Service);
    }
  const generateEmail=()=>{
    let name=document.getElementById("input-name");
    let email=document.getElementById("input-email");
    let phone=document.getElementById("input-phone");
    let description=document.getElementById("input-description");

  let body= name.value + "%0A"+email.value+"%0A"+phone.value+"%0A"+description.value;
    var a = document.createElement("a");
    a.href = "mailto:customer.services@tsrmexico.com?&subject=Information and Meeting&body="+body;
    a.click();

  }
  return (
    <Box className={classes.Back}>
      <IconButton
        onClick={ChangePage.bind(this, 0)}
        style={{
          position: "fixed",
          right: "32px",
          top: "25px",
          backgroundColor: "white",
        }}
      >
        <ArrowBack />
      </IconButton>
      <Paper className={classes.FormBackground}></Paper>
      <Paper className={classes.Form}>
        <Grid container spacing={2}>
          <Grid
            item
            md={12}
            style={{
              margin: "16px",
              padding: "32px",
              backgroundColor: "#0e2a3d",
            }}
          >
            <Typography
              className="Fonts Titles"
              style={{ color: "#fff", fontSize: "24px" }}
            >
              Dirección:
            </Typography>
            <Typography className="Fonts Textos" style={{ color: "#fff" }}>
              TSR de México S. A. de C. V. Av. Paseo de la Reforma 222 Oficina
              37 B, Col. Juárez Alcaldía Cuauhtémoc C. P. 06600, Ciudad de
              México, México
            </Typography>
            <Typography
              className="Fonts Titles"
              style={{ color: "#fff", fontSize: "24px" }}
            >
              Telefono:
            </Typography>
            <Typography className="Fonts Textos" style={{ color: "#fff" }}>
              {" "}
              +52155 1798 0375
            </Typography>
            <Typography
              className="Fonts Titles"
              style={{ color: "#fff", fontSize: "24px" }}
            >
              Correo:
            </Typography>
            <Typography className="Fonts Textos" style={{ color: "#fff" }}>
              customer.services@tsrmexico.com
              <a href="https://wa.me/525580187128/?text=I%20want%20more%20information">
                <IconButton style={{ marginLeft: "200px" }}>
                  <WhatsApp fontSize="large" style={{ color: "#fff" }} />
                </IconButton>
              </a>
            </Typography>
          </Grid>
          <Grid item lg={12}>

          <Grid container style={{padding:"20px"}} spacing={3}>
            <Grid item md={12} lg={5} style={{borderRight:"1px solid #0e2a3d"}}>
            <Typography
              className="Fonts Titles"
              style={{ color: "#fff", fontSize: "24px" , color:"#0e2a3d"}}
            >
              Envianos un correo
            </Typography>
            <FormControl style={{ display: "block", width: "100%" }}>
            <TextField fullWidth id="input-name" label="Nombre" variant="standard" />
            </FormControl>
            <FormControl style={{ display: "block", width: "100%" }}>
            <TextField fullWidth id="input-email" label="Correo" variant="standard" />
            </FormControl>
            <FormControl style={{ display: "block", width: "100%" }}>
            <TextField fullWidth id="input-phone" label="Telefono" variant="standard" />
            </FormControl>
            
             </Grid> 
             <Grid item md={12} lg={7}>
             <FormControl style={{ display: "block", width: "85%" }}>
              
              <TextField
          id="input-description"
          label="Descripcion"
          fullWidth
          multiline
          rows={8}
          variant="standard"
        />
            </FormControl>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#0e2a3d",
                color: "#fff",
                display: "block",
                width:"100%"
              }}
              onClick={generateEmail}
            >
              Enviar Mensaje
            </Button>
             </Grid> 
          </Grid>

          </Grid>
        </Grid>
      </Paper>

      <Julius ChangePage={ChangePage} ActiveServices={ActiveServices}/>
      <Footer ChangePage={ChangePage} />
    </Box>
  );
}
