import Header from "./Header";
import Footer from "./Footer";
import { Box, Container } from "@material-ui/core";

const Layout = (props) => {
  const {children}= props;
  const ChangePage=(page)=>{
    props.ChangePage(page);
  }
  const ActiveServices=(Service)=>{
    props.ActiveServices(Service);
    }
  return (
    <>
      <Header ChangePage={ChangePage} ActiveServices={ActiveServices}/>
      <Box style={{border:"1px solid transparent",marginBottom:"200px"}}>
      {children}
      </Box>
      
      <Footer ChangePage={ChangePage}/>
    </>
  );
};

export default Layout;
