import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Icon1 from "../../components/assets/ÍCONOS/Icon1.png";
import Icon2 from "../../components/assets/ÍCONOS/Icon2.png";
import Icon3 from "../../components/assets/ÍCONOS/Icon3.png";
import Icon5 from "../../components/assets/ÍCONOS/Icon5.png";
import Icon6 from "../../components/assets/ÍCONOS/Icon6.png";
import Icon7 from "../../components/assets/ÍCONOS/Icon7.png";
import Icon8 from "../../components/assets/ÍCONOS/Icon8.png";
import Icon9 from "../../components/assets/ÍCONOS/Icon9.png";
import { Box } from "@material-ui/core";
import GlobalServices from "../Services/GlobalServices";
import { ServicesData } from "../../data/ServicesData";

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage: "#164061",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#164061",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "1px 1px 1px 1px rgba(0,0,0,.25)",
    marginTop:"30px",
    border: "4px solid #999933",
    
  },
  active: {
    border: "4px solid #999933",
    backgroundImage: "white",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    width: 100,
    height: 100,
    marginTop:"10px",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <img src={Icon1} style={{ width: "70%" }} />,
    2: <img src={Icon2} style={{ width: "70%" }} />,
    3: <img src={Icon3} style={{ width: "70%" }} />,
    4: <img src={Icon5} style={{ width: "70%" }} />,
    5: <img src={Icon6} style={{ width: "70%" }} />,
    6: <img src={Icon7} style={{ width: "70%" }} />,
    7: <img src={Icon8} style={{ width: "70%" }} />,
    8: <img src={Icon9} style={{ width: "70%" }} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#164061",
  },
  Service:{
    backgroundColor:"white",
    padding:"16px",
  }
}));

function getSteps() {
  return [
    "Consultoría",
    "Pruebas de Laboratorio",
    "Certificación",
    "Dictámenes",
    "Inspección",
    "Implementación de laboratorios, unidad de inspección y organismos de certificación",
    "Capacitación y entrenamiento",
    "Representación en asuntos gubernamentales",
  ];
}
export default function BarMenu(props) {
  const { PassStep } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(PassStep);
  const steps = getSteps();
  const ServicesDataArray=ServicesData;
 
  useEffect(() => {
    setActiveStep(PassStep);
  }, [PassStep]);
  const handleStep = (step) => () => {
      if(step!==activeStep){
    setActiveStep(step);
      }
      else{
          props.ReturnStep(-1);
      }
  };

  return (
    <div className={classes.root} style={{ display: PassStep === -1 ? "none" : "block" }}>
      <Stepper
        style={{
          backgroundColor: "#164061",
          marginTop: "-35px",
        }}
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={ColorlibStepIcon}
              onClick={handleStep(index)}
            />
          </Step>
        ))}
      </Stepper>
      <Box className={classes.Service}>
      <GlobalServices Data={activeStep!==-1? ServicesDataArray[activeStep]:[]}/>
      </Box>
    </div>
  );
}
