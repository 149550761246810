import Layout from "../components/layout/Layout";

import { Container, Typography, Button, Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Questions } from "../data/Questions";
const useStyles = makeStyles((theme) => ({
  btn: {
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  img: {
    width: "100%",
    height: "auto",
    boxShadow: "0px 2px 20px rgba(0,0,0,0.4)",
  },
}));

export default function Faqs(props) {
  const classes = useStyles();
  const myQuestions = Questions;
  const ChangePage = (page) => {
    props.ChangePage(page);
  };
  const ActiveServices=(Service)=>{
    props.ActiveServices(Service);
    }
  return (
    <Layout ChangePage={ChangePage} ActiveServices={ActiveServices}>
      <Container>
        {myQuestions.map((quest, index) => {
          return (
            <>
              <Typography
                variant="h4"
                align="left"
                gutterBottom
                style={{ color: "#999933", marginTop: "5pc" }}
                className="Fonts Titles"
              >
                {quest.Question}
              </Typography>
              <Typography
                variant="subtitle1"
                align="left"
                gutterBottom
                style={{ color: "black" }}
                className="Fonts Titles"
              >
                {quest.Answer}
              </Typography>
            </>
          );
        })}
      </Container>
    </Layout>
  );
}
