export const ServicesData = [
  {
    Title: "Consultoría y Gestión",
    SubTitle:
      "Nuestros expertos analizan sus necesidades para proveer soluciones adecuadas en temas de evaluación de la conformidad",
    Points: [
      {
        Text: "Consultoría para fabricantes e importadores sobre el proceso de certificación",
      },
      {
        Text: "Diseñamos la mejor estrategia para lograr que sus líneas de productos sean certificados en el tiempo adecuado y de acuerdo a sus necesidades",
      },
      {
        Text: "Buscamos las mejores opciones para realizar las pruebas de laboratorio y el proceso de certificación; tenemos la posibilidad de trabajar con cualquiera de los laboratorios y organismos de certificación acreditados y aprobados para ese efecto",
      },
      {
        Text: "Analizamos sus productos y creamos una propuesta de valor para el proceso de certificación, que va desde la importación de las muestras, revisión previa de los productos, hasta el proceso de visitas de vigilancia para que los certificados siempre se mantengan vigentes",
      },
      {
        Text: "Gestionamos enteramente sus certificados antes los organismos de certificación",
      },
      {
        Text: "Le ayudamos a identificar los requisitos aplicables a sus productos que pueden incluir cumplimiento con normas de seguridad, de eficiencia energética, de consumo energético, de compatibilidad, de telecomunicaciones, requisitos de información comercial al usuario final y otros que pudieran ser aplicables",
      },
      {
        Text: "En el caso de ser necesario le podemos brindar la asistencia para obtener las constancias o dictámenes de acuerdo con las normas de información comercial, para ello analizamos las garantías, empaques y manuales de los productos con el fin de que cumplan con las regulaciones",
      },
    ],
  },
  {
    Title: "Pruebas de Laboratorio",
    SubTitle:
      "Nuestra experiencia y relaciones  nos permite ofrecer diferentes alternativas de laboratorios para que tus negocios cumplan con sus metas de tiempo y costo.",
    Points: [
      {
        Text: "Apoyamos con todos los pasos que le permitan realizar las pruebas de laboratorio, las cuales van a ser utilizadas en el proceso de certificación de cumplimiento.",
      },
      {
        Text: "Damos soporte para la compra y manejo de muestras para el proceso de pruebas",
      },
      {
        Text: "Realizamos una evaluación preliminar de cumplimiento",
      },
      {
        Text: "Seleccionamos, coordinamos y trabajamos con el laboratorio de pruebas más adecuado para usted; podemos trabajar con cualquiera de los laboratorios de pruebas acreditados y aprobados en México",
      },
      {
        Text: "Atendemos y ayudamos a solucionar las desviaciones sobre el proceso de pruebas si estas llegaran a suceder",
      },
    ],
  },
  {
    Title: "Certificación",
    SubTitle:
      "Diseñamos la mejor estrategia para lograr que sus líneas de productos sean certificados en el tiempo adecuado y de acuerdo a sus necesidades.",
    Points: [
      {
        Text: "Le ayudamos a conformar el expediente de certificación que será sometido al proceso de certificación",
      },
      {
        Text: "Seleccionamos el organismo de certificación más adecuado para sus necesidades, podemos trabajar con cualquiera de los Organismos de Certificación acreditados y aprobados",
      },
      {
        Text: "Proveemos seguimiento paso a paso al proceso de certificación",
      },
      {
        Text: "Le ayudamos a solucionar las posibles desviaciones que se encuentren el proceso de certificación",
      },
      {
        Text: "Nos hacemos cargo del control de sus certificaciones para prevenir que sus certificados expiren",
      },
      {
        Text: "Le proveemos asistencia para la atención de las visitas de vigilancia para prevenir que sus certificados sean suspendidos o cancelados y se pueda iniciar el proceso de renovación",
      },
      {
        Text: "Le ayudamos a que sus productos siempre se encuentren certificados y que sus certificados siempre estén vigentes",
      },
    ],
  },
  {
    Title: "Dictámenes Técnicos",
    SubTitle:
      "Proveemos los elementos que acreditan a quienes lo obtienen que cuentan con el conocimiento y requisitos en materia de la evaluación de la conformidad.",
    Points: [
      {
        Text: "Realizamos el análisis técnico que nos permitirá saber si sus productos deben de cumplir con normatividad de acuerdo con la funcionalidad, campo de aplicación de las normas y asignación de la fracción arancelaria con la que se ha clasificado su producto.",
      },
      {
        Text: "Si fuera necesario ayudamos a realizar las consultas formales ante las autoridades correspondientes para determinar las regulaciones aplicables a su producto",
      },
      {
        Text: "Dentro del marco regulatorio, ayudamos a determinar cuáles Normas Oficiales Mexicanas se deben cumplir de acuerdo a las características de su producto",
      },
    ],
  },
  {
    Title: "Inspección",
    SubTitle:
      "Nuestro equipo técnico los ayudará a inspeccionar sus productos ante requisito descrito en un pliego petitorio o de la conformidad.",
    Points: [
      {
        Text: "Ayudamos a que sus productos puedan cumplir con las inspecciones necesarias para mantener la vigencia de los certificados de cumplimiento NOM",
      },
      {
        Text: "Le ayudamos a realizar los trámites de constancias o dictámenes ante las Unidades de Inspección acreditadas y aprobadas",
      },
    ],
  },
  {
    Title:
      "Implementación de laboratorios, unidades de inspección y organismos de certificación.",
    SubTitle:
      "Nuestros expertos trabajarán en conjunto en todas las actividades de implementación, asesorando puntualmente en cada uno de los procedimientos.",
    Points: [
      {
        Text: "Consultoría Organismos de la evaluación de la conformidad",
      },
      {
        Text: "Realizamos proyectos para establecer y acreditar Organismos de Certificación de productos, acreditados y aprobados conforme a las Normas Oficiales Mexicanas y Normas Mexicanas.",
      },
      {
        Text: "Consultoría Laboratorios.",
      },
      {
        Text: "Realizamos proyectos para establecer y acreditar Laboratorios de pruebas acreditados y aprobados conforme a las Normas Oficiales Mexicanas y Normas Mexicanas",
      },
      {
        Text: "Consultoría para proyectos específicos relacionados con la evaluación de la conformidad.",
      },
      {
        Text: "¿Tiene alguna necesidad específica sobre, pruebas, inspecciones, certificaciones, o cualquier tema relacionado?; por favor contáctenos.",
      },
    ],
  },
  {
    Title: "Capacitación y entrenamiento",
    SubTitle:
      "Somos tu aliado y ayudamos a dirigir a tus expertos para que puedan transformar y proveer soluciones de la evaluación de la conformidad.",
    Points: [
      {
        Text: "Entrenamiento sobre evaluación de la conformidad; ISO/IEC 17025; ISO/IEC 17065 e ISO/IEC 17020",
      },
      {
        Text: "Proveemos cursos sobre las bases para la certificación NOM de México",
      },
      {
        Text: "Entrenamiento técnico sobre la diferentes Normas Oficiales Mexicanas aplicables a productos eléctricos y electrónicos, telecomunicaciones, información comercial",
      },
      {
        Text: "Proveemos cursos sobre las políticas, reglas y procedimientos de evaluación de la conformidad de México.",
      },
      {
        Text: "Cursos sobre la implementación de laboratorios de pruebas, unidades de verificación y organismos de certificación.",
      },
    ],
  },
  {
    Title: "Representación en asuntos gubernamentales",
    SubTitle:
      "Somos su aliado para guiar cualquier trámite de la evaluación de la conformidad.",
    Points: [
      {
        Text: "Podemos representarlo y guiarle ante cualquier trámite solicitado relacionado con el tema de la evaluación de la conformidad",
      },
      {
        Text: "Apoyamos con temas legales que estén relacionados con asuntos relacionados con el tema de evaluación de la conformidad",
      },
    ],
  },
];
