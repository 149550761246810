import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { ExtensionRounded, MenuBook } from "@material-ui/icons";
import "../../pages/Style.scss";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
  },
}));

export default function GlobalServicesMovil(props) {
  const { Data } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={5}>
        <Box style={{ width: "100%" }}>
          <Box
            style={{
              width: "60%",
              marginLeft: "15%",
              textAlign: "center",
              marginTop: "50px",
            }}
          >
            <Typography
              variant="h4"
              className="Fonts Titles"
              style={{ color: "#999933" }}
            >
              {Data.Title}
            </Typography>
            <br />
            <Typography
              paragraph
              className="Fonts Text"
              style={{ fontWeight: "bold" }}
            >
              {Data.SubTitle}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={7}>
        <Box className={classes.root}>
          {Data.Points
            ? Data.Points.map((Point, index) => (
                <Box
                  style={{
                    width: "100%",
                    margin: "5%",
                    height: "auto",
                    backgroundColor: "transparent",
                  }}
                >
                  <Box
                    style={{
                      border: "2px dashed",
                      borderColor: index % 2 ? "rgb(22, 64, 97)" : "#999933",
                      float: "right",
                      width: "95%",
                      height: "auto",
                      padding: "7px",
                      display: "block",
                      margin:"7px",
                    }}
                  >
                    {index % 2 ? (
                      <Box
                        style={{
                          backgroundColor: "rgb(22, 64, 97)",
                          width: "100%",
                          height: "auto",
                          display: "block",
                        }}
                      >
                        <ExtensionRounded
                          style={{ fill: "#fff", marginLeft: "4%" }}
                        />
                      </Box>
                    ) : (
                      <Box
                        style={{
                          backgroundColor: "#999933",
                          width: "100%",
                          height: "auto",
                          display: "block",
                        }}
                      >
                        <MenuBook style={{ fill: "#fff", marginLeft: "4%" }} />
                      </Box>
                    )}
                    <Typography
                      className="Fonts Text"
                      style={{
                        textAlign: "justify",
                        display: "inline-block",
                        wordBreak: "break-word",
                      }}
                      paragraph
                      className="Fonts Text"
                    >
                      {Point.Text}
                    </Typography>
                  </Box>
                </Box>
              ))
            : null}
        </Box>
      </Grid>
    </Grid>
  );
}
