import Layout from "../components/layout/Layout";

import {
  Container,
  Typography,
  Button,
  Box,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  btn: {
    background: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  img: {
    width: "100%",
    height: "auto",
    boxShadow: "0px 2px 20px rgba(0,0,0,0.4)",
  },
}));

const Home = (props) => {
  const classes = useStyles();

  const ChangePage=(page)=>{
    props.ChangePage(page);
  }
  const ActiveServices=(Service)=>{
    props.ActiveServices(Service);
    }
  return (
    <Layout ChangePage={ChangePage} ActiveServices={ActiveServices}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h4"
              align="left"
              gutterBottom
              style={{ color: "#999933", marginTop: "5pc" }}
              className="Fonts Titles"
            >
              Evaluación de la conformidad
            </Typography>
            <Typography
              variant="subtitle1"
              align="left"
              gutterBottom
              style={{ color: "black" }}
              className="Fonts Titles"
            >
              Podemos ayudarle a cumplir…
            </Typography>
            <Box
              style={{
                width: "90%",
                textAlign: "left",
                whiteSpace: "initial",
                color:"#333333"
              }}
              className="Fonts Text"
            >
              Testing, Services and Regulations de México, S. A. de C. V. es una empresa de origen Mexicano, la cual se dedica a proveer soluciones sobre temas de evaluación de la conformidad, le ayudamos a coordinar, pruebas, gestión, manejo de certificaciones, establecimiento de organizaciones acreditadas, capacitación, y más.
             <br/> Si usted necesita cumplir con las Normas Oficiales Mexicanas u otras para importar o comercializar, somos la mejor opción para ayudarle a cumplir.

            </Box>
            <Box
              style={{
                width: "90%",
                textAlign: "center",
                marginTop: "16px",
              }}
            >
              <Button
                style={{
                  backgroundColor: "#999933",
                  color: "black",
                  margin: "4px",
                }}
                className="Fonts Text"
                onClick={()=>{ChangePage(2)}}
              >
                ¿Cómo podemos ayudarle?

              </Button>
              <Button
                style={{
                  color: "#999933",
                  backgroundColor: "#333",
                  margin: "4px",
                }}
                className="Fonts Text"
                onClick={()=>{ChangePage(3)}}
              >
                ¿Cómo podemos ayudarle?

              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              style={{
                width: "100%",
                textAlign: "center",
                marginTop: "72px",
              }}
            >
              
              <iframe
                width="100%"
                height="315"
                src="./Videos/HomeVideo.mp4"
                title="Welcome Video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Home;
