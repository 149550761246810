// use your social media url
// add social media which you want to the array.
// if you add some social media objects, go to the /components/Footer.js and /pages/about.js
// you can find out  Material icon. => https://material-ui.com/components/material-icons/

export const socialMedia = {
  instagram: "https://www.instagram.com/",
  facebook: "https://www.facebook.com/",
  whatsapp: "https://wa.me/525580187128/?text=I%20want%20more%20information",
  // You can add like this
  // twitter: "https://twitter.com",
};
