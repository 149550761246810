import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import Social from "../Social";
import FooterImage from "../assets/BANNERS/Footer.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundImage: `url(${FooterImage})`,
    backgroundPosition: 'center', 
    backgroundSize: '100% 150px', 
    backgroundRepeat: 'no-repeat',
    // margin: 'auto',
    width: '100%',
    position: "fixed",
    overflow: "hidden",
    bottom:"0",
    },
  link: {
    fontSize: "1.25em",
    color: "#fff",
    "&:hover": {
      color: theme.palette.info.main,
    },
  },
}));

const Footer = (props) => {
  const classes = useStyles();
  const ChangePage=(page)=>{
    console.log(page);
    props.ChangePage(page);
  }
  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container direction="column" >
          <Social />
        </Grid>
        <Grid
          item
          container
          justify="center"
          style={{
            textDecoration: "none",
          }}
        >
          <Typography className="Fonts Text" style={{color: "#fff", fontSize: "1em"}} onClick={ChangePage.bind(this,1)}>
            &copy;TSR México
          </Typography>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
