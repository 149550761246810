import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import { socialMedia } from "../data/socialMedia";
import { WhatsApp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  snsIcon: {
    marginTop:"5px",
    width: "20px",
    height: "20px",

    [theme.breakpoints.down("xs")]: {
      width: "15px",
      height: "15px",
    },
    "&:hover": {
      color: theme.palette.info.main,
    },
  },
}));

const Social = ({ color }) => {
  const classes = useStyles();

  const { instagram, facebook, whatsapp } = socialMedia;

  return (
    <Grid item container spacing={2} justify="center">
      {/* <Grid
        item
        component={"a"}
        target="_blank"
        rel="noreferrer noopener"
        href={facebook}
      >
        <FacebookIcon
          className={classes.snsIcon}
          style={{color:"white"}}
        />
      </Grid>
      <Grid
        item
        component={"a"}
        target="_blank"
        rel="noreferrer noopener"
        href={instagram}
      >
        <InstagramIcon
          className={classes.snsIcon}
          style={{color:"white"}}
        />
      </Grid> */}
      <Grid
        item
        component={"a"}
        target="_blank"
        rel="noreferrer noopener"
        href={whatsapp}
      >
        <WhatsApp
          className={classes.snsIcon}
          style={{color:"white"}}
        />
      </Grid>
      {/* add social media*/}
    </Grid>
  );
};

export default Social;
