import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { IconButton, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import Icon1 from "../../components/assets/ÍCONOS/Icon1.png";
import Icon2 from "../../components/assets/ÍCONOS/Icon2.png";
import Icon3 from "../../components/assets/ÍCONOS/Icon3.png";
import Icon4 from "../../components/assets/ÍCONOS/Icon4.png";
import Icon5 from "../../components/assets/ÍCONOS/Icon5.png";
import Icon6 from "../../components/assets/ÍCONOS/Icon6.png";
import Icon7 from "../../components/assets/ÍCONOS/Icon7.png";
import Icon8 from "../../components/assets/ÍCONOS/Icon8.png";
import Icon9 from "../../components/assets/ÍCONOS/Icon9.png";
import { Box } from "@material-ui/core";
import GlobalServicesMovil from "../Services/GlobalServicesMovil";
import { ServicesData } from "../../data/ServicesData";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  Service: {
    backgroundColor: "white",
    padding: "16px",
  },
});
function getSteps() {
  return [
    "Consultoría",
    "Pruebas de Laboratorio",
    "Certificación",
    "Mantenimiento y renovacion de certificación",
    "Dictámenes",
    "Inspección",
    "Implementación de laboratorios, unidad de inspección y organismos de certificación",
    "Capacitación y entrenamiento",
    "Representación en asuntos gubernamentales",
  ];
}
export default function BarMenuMovil(props) {
  const { PassStep } = props;
  const classes = useStyles();
  const theme = useTheme();
  const steps = getSteps();
  
  const ServicesDataArray=ServicesData;
  const [activeStep, setActiveStep] = useState(PassStep);
  useEffect(() => {
    setActiveStep(PassStep);
  }, [PassStep]);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStep = () => {
    props.ReturnStep(-1);
  };

  const icons = {
    0: <img src={Icon1} style={{ width: "30%" , marginLeft:"35%"}} />,
    1: <img src={Icon2} style={{ width: "30%" , marginLeft:"35%"}} />,
    2: <img src={Icon3} style={{ width: "30%" , marginLeft:"35%"}} />,
    3: <img src={Icon4} style={{ width: "30%" , marginLeft:"35%"}} />,
    4: <img src={Icon5} style={{ width: "30%" , marginLeft:"35%"}} />,
    5: <img src={Icon6} style={{ width: "30%" , marginLeft:"35%"}} />,
    6: <img src={Icon7} style={{ width: "30%" , marginLeft:"35%"}} />,
    7: <img src={Icon8} style={{ width: "30%" , marginLeft:"35%"}} />,
    8: <img src={Icon9} style={{ width: "30%" , marginLeft:"35%"}} />,
  };
  

  return (
    <>
      <MobileStepper
        variant="dots"
        steps={9}
        position="static"
        activeStep={activeStep}
        className={classes.root}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === 8} style={{backgroundColor:"#333333",color:"#999933"}}>
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}  style={{backgroundColor:"#333333",color:"#999933"}}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
        style={{ display: PassStep === -1 ? "none" : "flex" }}
      />
      <IconButton
        onClick={handleStep.bind()}
        style={{ display: PassStep === -1 ? "none" : "block", margin: "auto" }}
      >
        <ArrowBack fontSize="small" style={{ fill: "#999933" }} />
      </IconButton>
      <Box className={classes.Service}  style={{ display: PassStep === -1 ? "none" : "block", margin: "auto" }}>
        {icons[activeStep]}
        <GlobalServicesMovil Data={activeStep!==-1? ServicesDataArray[activeStep]:[]}/>
      </Box>
    </>
  );
}
