import Layout from "../components/layout/Layout";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import BarMenu from "../components/BarMenu/BarMenu";
import "./Style.scss";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Icon1 from "../components/assets/ÍCONOS/Icon1.png";
import Icon2 from "../components/assets/ÍCONOS/Icon2.png";
import Icon3 from "../components/assets/ÍCONOS/Icon3.png";
import Icon5 from "../components/assets/ÍCONOS/Icon5.png";
import Icon6 from "../components/assets/ÍCONOS/Icon6.png";
import Icon7 from "../components/assets/ÍCONOS/Icon7.png";
import Icon8 from "../components/assets/ÍCONOS/Icon8.png";
import Icon9 from "../components/assets/ÍCONOS/Icon9.png";
import BarMenuMovil from "../components/BarMenu/BarMenuMovil";
import { useEffect, useState } from "react";

const ServicesList = [
  { Path: Icon1, Name: "Consultoría y Gestión", marginTop: "30%" },
  { Path: Icon2, Name: "Pruebas de Laboratorio", marginTop: "30%" },
  { Path: Icon3, Name: "Certificación", marginTop: "40%" },

  { Path: Icon5, Name: "Dictámenes", marginTop: "40%" },
  { Path: Icon6, Name: "Inspección", marginTop: "40%" },
  {
    Path: Icon7,
    Name: "Implementación de entidades acreditadas",
    marginTop: "30%",
  },
  { Path: Icon8, Name: "Capacitación y entrenamiento", marginTop: "30%" },
  {
    Path: Icon9,
    Name: "Representación en temas legales",
    marginTop: "30%"
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "200px",
    height: "300px",
  },
  Grid: {
    textAlign: "center",
    paddingLeft: "16px",
    paddingRight: "16px",
    marginTop: "3pc",
  },
  menuWeb: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  menuMovil: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
    },
  },
}));
const Services = (props) => {
  const { ActServ } = props;
  const classes = useStyles();
  const [PassStep, setPassStep] = useState(-1);
  const ChangePage = (page) => {
    props.ChangePage(page);
  };
  const ActivePage = (select) => {
    setPassStep(select);
  };
  useEffect(() => {
    if (ActServ !== null) {
      ActivePage(ActServ);
    }
  }, [ActServ]);
  const ActiveServicesFuntion = (Service) => {
    props.ActiveServices(Service);
  }
  return (
    <Layout ChangePage={ChangePage} ActiveServices={ActiveServicesFuntion}>
      <Box className={classes.menuWeb}>
        <BarMenu PassStep={PassStep} ReturnStep={ActivePage} />
      </Box>
      <Box className={classes.menuMovil}>
        <BarMenuMovil PassStep={PassStep} ReturnStep={ActivePage} />
      </Box>
      <Grid
        container
        spacing={0}
        className={classes.Grid}
        style={{
          maxWidth: "100%",
          margin: "auto",
          display: PassStep !== -1 ? "none" : "flex",
        }}
      >
        <Grid item xs={12} md={4}>
          <Typography
            variant="h4"
            align="center"
            style={{
              color: "#333333",
              marginTop: "30%",
            }}
            className="Fonts Titles"
          >
            Las soluciones sobre temas de evaluación de la conformidad de TSR le ayudarán a sus necesidades comerciales
          </Typography>
        </Grid>
        <Grid item md={8}>
          <div className="container-box">
            <div className="box-cards">
              {ServicesList.map((service, index) => {
                return (
                  <div className="single-card">
                    <div className="carta-box">
                      <div className="carta">
                        <div className="cara">
                          <Box
                            style={{
                              width: "150px",
                              height: "150px",
                              borderRadius: "50%",
                              border: "4px solid #999933",
                            }}
                          >
                            <img
                              src={service.Path}
                              alt={service.Name}
                              style={{ marginTop: "30px", width: "60%" }}
                            />
                          </Box>
                        </div>
                        <div className="cara detras">
                          <Box
                            style={{
                              width: "150px",
                              height: "150px",
                              borderRadius: "50%",
                              backgroundColor: "#333333",
                              border: "4px solid #999933",
                            }}
                            onClick={ActivePage.bind(this, index)}
                          >
                            <Typography
                              className={"TextServices"}
                              style={{
                                marginTop: service.marginTop,
                                color: "#fff",
                                fontSize: "18px",
                              }}
                            >
                              {service.Name}
                            </Typography>
                          </Box>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Services;
