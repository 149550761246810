import {
  Box,
  Container,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@material-ui/core";
import "./Style.scss";
import Footer from "../components/layout/Footer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Bg from "../components/assets/FOTOS/FOTO4.png";
import { ArrowBack } from "@material-ui/icons";
import Julius from "../components/ChatBot/Julius";
import { Info } from "../data/AboutText";
import { useState } from "react";
const useStyles = makeStyles((theme) => ({
  Back: {
    [theme.breakpoints.down("lg")]: {
      backgroundPosition: "0px -15px",
      backgroundSize: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      backgroundPosition: "center",
      backgroundSize: "cover",
    },
    backgroundImage: `url(${Bg})`,

    backgroundRepeat: "no-repeat",
    // margin: 'auto',
    width: "100%",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      backgroundSize: "cover",
    },
  },
  Globo: {
    padding: "8px",
    margin: "auto",
    marginTop: "32px",
    backgroundColor: "#333333ca",
    borderRadius: "8px",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
      width: "30%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    },
  },
  ReturnButton: {
    [theme.breakpoints.down("lg")]: {
      position: "fixed",
      left: "32px",
      top: "50px",
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      textAlign: "left",
      display: "block",
      marginTop: "-32px",
    },
  },
  GridTable: {
    [theme.breakpoints.down("lg")]: {
      display: "block",  
    
      overflow: "auto",
      maxHeight: "100vh",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginTop: "40px",
      
    
      overflow: "auto",
      maxHeight: "100vh",
    },
  },
  Modal: {
    padding: "8px",
      margin: "auto",
      backgroundColor: "#333333ca",
      borderRadius: "8px",
      
    [theme.breakpoints.down("lg")]: {
      maxHeight: "600px",
      overflow: "auto",
      transform: "translate(-50%, -50%)",
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "50%",
      
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: "600px",
      overflow: "auto",
      display: "block",
      width: "90%",
    },
  },
}));

const About = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const Inform = Info;
  const [openModal, setopenModal] = useState();
  const [Title, setTitle] = useState();
  const [Content, setContent] = useState();
  const ChangePage = (page) => {
    props.ChangePage(page);
  };
  const HandleOpenModal = (Data) => {
    setTitle(Data.Title);
    setContent(Data.CompleteAnswer);
    setopenModal(true);
  };
  const HandlecloseModal = (Data) => {
    setTitle("");
    setContent("");
    setopenModal(false);
  };
  const ActiveServices=(Service)=>{
  props.ActiveServices(Service);
  }

  return (
    <Box className={classes.Back}>
      <Box className={classes.ReturnButton}>
        <Typography>
          <IconButton
            onClick={ChangePage.bind(this, 0)}
            style={{
              backgroundColor: "white",
            }}
          >
            <ArrowBack />
          </IconButton>
        </Typography>
      </Box>
      <Box className={classes.GridTable}>
        <Grid
          container
        >
          {Inform.map((element, index) => {
            return (
              <Grid item xs={12} md={12}>
                <Box
                  onClick={HandleOpenModal.bind(this, element)}
                  className={classes.Globo}
                  style={{
                    display: openModal ? "none" : "block",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{ color: "#999933" }}
                    className="Fonts Titles"
                    align="center"
                  >
                    {element.Title}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    style={{ color: "white", textAlign: "justify" }}
                    className="Fonts Textos"
                    align="center"
                  >
                    {element.ShortAnswer}
                  </Typography>
                  <Typography
                    style={{ color: "#fff", fontSize: "12px" }}
                    className="Fonts Titles"
                    align="right"
                    onClick={HandleOpenModal.bind(this, element)}
                  >
                    {"Read More..."}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
          <Grid item xs={12} md={12}>
            <Box
              className={classes.Globo}
              style={{
                display: openModal ? "none" : "block",
              }}
            >
              <Typography
                variant="h5"
                style={{ color: "#999933" }}
                className="Fonts Titles"
                align="center"
              ></Typography>
              <Typography
                variant="h6"
                component="p"
                style={{ color: "white", textAlign: "justify" }}
                className="Fonts Textos"
                align="center"
              ></Typography>
              <Typography
                style={{ color: "#fff", fontSize: "12px" }}
                className="Fonts Titles"
                align="right"
              ></Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Modal className={classes.Back} open={openModal} onClose={HandlecloseModal}>
        <Box
        className={classes.Modal}
        >
          <Typography
            variant="h5"
            style={{ color: "#999933" }}
            className="Fonts Titles"
            align="center"
          >
            {Title}
          </Typography>
          <Typography
            variant="h6"
            component="p"
            style={{ color: "white", textAlign: "justify" }}
            className="Fonts Textos"
            align="center"
          >
            {Content}
          </Typography>
        </Box>
      </Modal>
      <Julius ChangePage={ChangePage} ActiveServices={ActiveServices}/>
      <Footer ChangePage={ChangePage} />
    </Box>
  );
};

export default About;
