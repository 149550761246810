export const Chat = [
    {
        Text: "Desea conocer cómo es la normatividad en México",
        URL: 4,
        Options: [],
    },
    {
        Text: "Desea Certificar un Producto",
        URL: null,
        Help: "¿Dónde proviene su producto?",
        Accumulator:true,
        Options: [
            {
                Text: "Nacional",
                URL: null,
                Help: "¿Qué tipo de producto es?",
                Accumulator:true,
                Options: [
                    {
                        Text: "Electrónico",
                        Accumulator:true,
                        URL: null,
                        Help: "¿Cómo desea ser contactado?",
                        Options: [
                            {
                                Text: "Email",
                                Sent:true,
                                URL: 3,
                                Options: [],
                            },
                            {
                                Text: "WhatApp",
                                Sent:true,
                                URL: null,
                                Whats: true,
                                Options: [],
                            },

                        ]
                    },
                    {
                        Text: "Eléctrico",
                        Accumulator:true,
                        URL: null,
                        Help: "¿Cómo desea ser contactado?",
                        Options: [
                            {
                                Text: "Email",
                                Sent:true,
                                URL: 3,
                                Options: [],
                            },
                            {
                                Text: "WhatApp",
                                Sent:true,
                                URL: null,
                                Whats: true,
                                Options: [],
                            },
                        ]
                    },
                    {
                        Text: "Otro",
                        Accumulator:true,
                        URL: null,
                        Help: "¿Cómo desea ser contactado?",
                        Options: [
                            {
                                Text: "Email",
                                Sent:true,
                                URL: 3,
                                Options: [],
                            },
                            {
                                Text: "WhatApp",
                                Sent:true,
                                URL: null,
                                Whats: true,
                                Options: [],
                            },
                        ]
                    }
                ]
            },
            {
                Text: "Internacional",
                Accumulator:true,
                URL: null,
                Help: "¿Qué tipo de producto es?",
                Options: [
                    {
                        Text: "Electrónico",
                        Accumulator:true,
                        URL: null,
                        Help: "¿Cómo desea ser contactado?",
                        Options: [
                            {
                                Text: "Email",
                                Sent:true,
                                URL: 3,
                                Options: [],
                            },
                            {
                                Text: "WhatApp",
                                Sent:true,
                                URL: null,
                                Whats: true,
                                Options: [],
                            },

                        ]
                    },
                    {
                        Text: "Eléctrico",
                        Accumulator:true,
                        URL: null,
                        Help: "¿Cómo desea ser contactado?",
                        Options: [
                            {
                                Text: "Email",
                                Sent:true,
                                URL: 3,
                                Options: [],
                            },
                            {
                                Text: "WhatApp",
                                Sent:true,
                                URL: null,
                                Whats: true,
                                Options: [],
                            },
                        ]
                    },
                    {
                        Text: "Otro",
                        Accumulator:true,
                        URL: null,
                        Help: "¿Cómo desea ser contactado?",
                        Options: [
                            {
                                Text: "Email",
                                Sent:true,
                                URL: 3,
                                Options: [],
                            },
                            {
                                Text: "WhatApp",
                                Sent:true,
                                URL: null,
                                Whats: true,
                                Options: [],
                            },
                        ]
                    }
                ]
            }
        ],
    },
    {
        Text: "Desea Conocer los servicios que ofrecemos",
        URL: null,
        Help: "Servicios",
        Options: [
            {
                Text: "Consultoría",
                URL: null,
                Service: 0,
                Help: null,
                Options: []
            },
            {
                Text: "Pruebas de Laboratorio",
                URL: null,
                Service: 1,
                Help: null,
                Options: []
            },
            {
                Text: "Certificación",
                URL: null,
                Service: 2,
                Help: null,
                Options: []
            },
            {
                Text: "Dictámenes",
                URL: null,
                Service: 3,
                Help: null,
                Options: []
            },
            {
                Text: "Inspección",
                URL: null,
                Service: 4,
                Help: null,
                Options: []
            },
            {
                Text: "Implementación de Laboratorios",
                URL: null,
                Service: 5,
                Help: null,
                Options: []
            },
            {
                Text: "Capacitación y entrenamiento",
                URL: null,
                Service: 6,
                Help: null,
                Options: []
            },
            {
                Text: "Representación Gubernamental",
                URL: null,
                Service: 7,
                Help: null,
                Options: []
            },
        ],
    },
    {
        Text: "Desea ser contactado",
        URL: 3,
        Options: [],
    },
];