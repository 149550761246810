export const Info=[
    {
        Title:"¿QUIENES SOMOS?",
        ShortAnswer:"Testing, Services and Regulations de México S. A. de C. V. (TSR de México) es una empresa Socialmente Responsable de servicios …",
        CompleteAnswer:"Testing, Services and Regulations de México S. A. de C. V. (TSR de México) Somos una empresa de ingeniería, integrada por un grupo de profesionales que cuentan con una experiencia de más de 25 años en las actividades de evaluación de la conformidad en México y Latinoamérica.",
    },
    {
        Title:"¿QUÉ HACEMOS?",
        ShortAnswer:"Proveemos servicios de consultoría y asistencia sobre temas de evaluación de la conformidad ...",
        CompleteAnswer:"Proveemos servicios de consultoría y asistencia sobre temas de evaluación de la conformidad que pueden incluir entre otros:\n Consultoría y asistencia a empresas que tiene la necesidad de cumplir con las regulaciones de México y Latinoamérica.\n Proveemos el servicio de consultoría para establecer organizaciones de evaluaciones de la conformidad en México, como pueden ser laboratorios de prueba, unidades de inspección y organismos de certificación en México y Latinoamérica.\n Proveemos consultoría sobre los servicios de Pruebas, Inspecciones y certificaciones para una amplia variedad de productos y servicios, trabajando con los principales laboratorios de pruebas, unidades de verificación y organismos de certificación en México.",
    },
    {
        Title:"NUESTRA EMPRESA",
        ShortAnswer:"Somos una empresa de ingeniería con una experiencia de más de 25 años...",
        CompleteAnswer:"TSR de México es una empresa Socialmente Responsable de servicios que fundamenta sus operaciones en los más altos valores ética e Integridad, siendo la Misión, Visión, Declaración de ética y Valores los siguientes:",
    },
    {
        Title:"MISIÓN",
        ShortAnswer:"Proveer los servicios de consultoría, intermediación y asesoría en el menor tiempo posible, con el personal calificado y la tecnología adecuada, contribuyendo con el cumplimiento sostenible de las regulaciones en Latinoamérica…",
        CompleteAnswer:"Proveer los servicios de consultoría, intermediación y asesoría en el menor tiempo posible, con el personal calificado y la tecnología adecuada, contribuyendo con el cumplimiento sostenible de las regulaciones en Latinoamérica.\“Apoyamos a cumplir con las regulaciones de la región \“, \“Somos el medio que le ayuda a cumplir con las regulaciones de la región\”",
    },
    {
        Title:"VISIÓN",
        ShortAnswer:"Proveer servicios a nuestros clientes con el más alto índice de integridad, eficiencia y sin corrupción, para generar las utilidades que nos permitan crear empleos con un carácter social.",
        CompleteAnswer:"Proveer servicios a nuestros clientes con el más alto índice de integridad, eficiencia y sin corrupción, para generar las utilidades que nos permitan crear empleos con un carácter social.",
    },
    {
        Title:"DECLARACIÓN DE ÉTICA DE TSR DE MÉXICO",
        ShortAnswer:"\ “El más alto grado de integridad en la forma que realizamos los negocios es la cultura de nuestra organización, además es la base sobre la que realizamos nuestras operaciones, por lo que no hacemos concesiones sobre cualquier violación a esta declaración.\”",
        CompleteAnswer:"\“El más alto grado de integridad en la forma que realizamos los negocios es la cultura de nuestra organización, además es la base sobre la que realizamos nuestras operaciones, por lo que no hacemos concesiones sobre cualquier violación a esta declaración.\”",
    },
    {
        Title:"VALORES DE LA EMPRESA",
        ShortAnswer:"Los valores de nuestra empresa son la integridad, la ética, cero corrupción, la calidad, empatía y velocidad del servicio, que hacen una gran experiencia del servicio que brindamos…",
        CompleteAnswer:"Los valores de nuestra empresa son la integridad, la ética, cero corrupción, la calidad, empatía y velocidad del servicio, que hacen una gran experiencia del servicio que bridamos.\n El valor de nuestros servicios está caracterizado por la honestidad, comunicación constante, la innovación y la creatividad. La oferta de nuestros servicios se basa en la comprensión de las necesidades reales de nuestros clientes, enfocándonos en el análisis las situaciones y en las soluciones a largo plazo.\n La confidencialidad es uno de los valores primarios de la empresa, entendemos el valor de la secrecía de la información a la que tenemos acceso, por lo que tratamos toda la información recibida y comunicación con el cliente bajo la más estricta confidencialidad. \nEl valor de nuestra relación con los clientes se basa en la comunicación abierta y constante, que incluye por ejemplo emplear el tiempo adecuado para educar y explicar las razones en las que se basan nuestros sistemas, opiniones, procesos y en especial las razones en las que se basan las decisiones que tomamos en beneficio de nuestros clientes. \nLos valores hacia el interior de la compañía son aquellos que contribuyen al bien común y al del empleado teniendo entre otros",
    },
];