import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Zoom,
} from "@material-ui/core";
import { ArrowBack, Help, Home, Send, WhatsApp } from "@material-ui/icons";
import { useState } from "react";
import contacto from "../assets/CHAT/Chatbot.png";
import contactoMsg from "../assets/CHAT/Chatbotmsg.png";
import { Chat } from "../../data/JuliosChat";
export default function Julius(props) {
  const [checked, setChecked] = useState(false);
  const ChatTotal = Chat;
  const [ContiniusChat, setContiniusChat] = useState(Chat);
  const [PreChat, setPreChat] = useState(null);
  const [Title, setTitle] = useState("Selecciona una opción para continuar");
  const [Descrition, setDescrition] = useState("");
  const ActionsButton = (Opt) => {
    if(Opt.Service>=0){
      props.ActiveServices(Opt.Service);
    }
    else if (Opt.URL) {
      props.ChangePage(Opt.URL);
    }
    else {
      if(Opt.Accumulator){
        let Descript= Descrition +"%"+Opt.Text;
        setDescrition(Descript);
      }
      setPreChat(ContiniusChat);
      setTitle(Opt.Help);
      setContiniusChat(Opt.Options);
    }
  };
  const startAgain = () => {
    setTitle("Selecciona una option para continuar");
    setPreChat(null);
    setContiniusChat(ChatTotal);
  }
  const BackChat = () => {
    let title = PreChat ? PreChat.Help : "Selecciona una option para continuar";
    setTitle(title);
    let chat=PreChat ? PreChat : ChatTotal;
    setContiniusChat(PreChat);
  }
  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <Box
      style={{
        position: "fixed",
        width: "90px",
        right: "20px",
        bottom: "50px",
      }}
    >
      <IconButton
        // onClick={ChangePage.bind(this, 0)}
        onClick={handleChange}
      >
        <img
          alt="ContactIcon"
          src={contacto}
          style={{ width: "80px", zIndex: "5" }}
        />
      </IconButton>
      <Zoom in={checked}>
        <Box
          style={{
            backgroundColor: "white",
            width: "350px",
            height: "445px",
            borderRadius: "16px",
            zIndex: "100",
            right: "15px",
            bottom: "160px",
            position: "fixed",
            border: "1px solid #99993333",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 4%)",

          }}
        >
          <Box
            style={{
              width: "100%",
              height: "40px",
              backgroundColor: "#0e2a3d",
              borderRadius: "16px 16px 0 0",
            }}
          >
            <Typography
              align="center"
              className="Fonts Titles"
              style={{ paddingTop: "4px", color: "white", fontSize: "24px" }}
            >
              {/* {
                PreChat ? (<Box style={{ float: "left", marginLeft: "15px" }}>
                  <IconButton onClick={BackChat}>
                    <ArrowBack style={{ color: "#999933", marginTop: "-7px" }} />
                  </IconButton>

                </Box>) : null
              } */}
              ¿En que puedo ayudarte?
              {
                PreChat ? (<Box style={{ float: "right", marginRight: "15px" }}>

                  <IconButton onClick={startAgain}>
                    <Home style={{ color: "#999933", marginTop: "-7px" }} />
                  </IconButton>

                </Box>) : null
              }
            </Typography>

          </Box>
          <Box
            style={{
              width: "100%",
              height: "10px",
              backgroundColor: "#999933",
            }}
          >

          </Box>
          <Box
            style={{
              width: "100%",
              minHeight: "400px",
              maxHeight: "400px",
              overflowY: "auto",
              backgroundColor: "lightgrey",
              borderRadius: "0 0 16px 16px",
            }}
          >
            <Grid container>
              <Grid item lg={12}>

                <Box
                  style={{
                    width: "90%",
                    marginLeft: "5%",
                    borderRadius: "16px",
                    backgroundColor: "#164061",
                    padding: "4px",
                    display: "inline-block"
                  }}
                >
                  <img
                    alt="ContactIcon"
                    src={contactoMsg}
                    style={{
                      width: "15%",
                    }}
                  />
                  <Typography
                    className="Fonts Textos"
                    style={{ color: "white", display: "inline-block", marginLeft: "5%", width: "78%", }}
                  >
                    {Title}
                  </Typography>

                </Box>
              </Grid>

              {ContiniusChat.map((Opt) => (
                <Grid item md={12} >
                  <Box style={{width:"100%", textAlign:"center"}}>
                  <Button
                    onClick={ActionsButton.bind(this, Opt)}
                    variant="contained"
                    style={{
                      backgroundColor: "#333333",
                      color: "#999933",
                      fontSize: "14px",
                      width:"80%",
                      marginTop:"15px",
                    }}
                  >
                    {Opt.Text}
                  </Button>
                  </Box>
                </Grid>
              ))}


            </Grid>
          </Box>
          {/* <Box
            style={{
              width: "100%",
              height: "55px",
              borderRadius: "0 0 16px 16px",
              display: "flex",
              overflow: "auto",
            }}
          >
            <TextField
              style={{ width: "90%", margin: "auto" }}
              fullWidth
              placeholder="Ask your question?"
              InputProps={{
                endAdornment: (
                  <IconButton position="end">
                    <Send />
                  </IconButton>
                ),
              }}
            />
          </Box> */}
        </Box>
      </Zoom>
    </Box>
  );
}
