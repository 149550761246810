export const Questions = [
  {
    Question: "¿Existe la evaluación de la conformidad en México?",
    Answer:
      "Sí, de acuerdo con el esquema Mexicano, existe un marco legal para la evaluación de la conformidad en México, la evaluación de la conformidad incluye entre otros, los procesos de Pruebas, Inspección y Certificación. La evaluación de la conformidad se rige y ejerce mediante la Ley de la Infraestructura de la Calidad (LIC) de México y su reglamento (Refrirse al link https://www.dof.gob.mx/nota_detalle.php?codigo=5596009&fecha=01/07/2020).",
  },
  {
    Question: "¿Qué es la evaluación de la conformidad?",
    Answer:
      "En los términos de la Ley de la Infraestructura de la Calidad (LIC), se le denomina “evaluación de la conformidad” al proceso técnico que permite demostrar el cumplimiento con las Normas Oficiales Mexicanas (estándares), Normas Internacionales ahí referidos o de otras disposiciones legales. Comprende, entre otros, los procedimientos de muestreo, prueba, inspección, evaluación y certificación.",
  },
  {
    Question: "¿Qué es una Norma Oficial Mexicana (NOM)?",
    Answer:
      "De acuerdo con la Ley de la Infraestructura de la Calidad (LIC) de México; una NOM (o estándar) es una regulación oficial que se debe cumplir obligatoriamente, cuyo fin esencial es el fomento de la calidad para el desarrollo económico y la protección de los objetivos legítimos de interés público.",
  },
  {
    Question:
      "¿Cómo se si mi producto deben cumplir con alguna regulación o norma?",
    Answer:
      "Aquellos productos que son importados, comercializados, distribuidos y vendidos en México, en general deben de cumplir con las Normas Oficiales Mexicanas o regulaciones aplicables que les correspondan, si el producto se encuentra dentro del campo de aplicación de una NOM, entonces debe cumplir con la misma obligatoriamente independientemente de sus condiciones de importación o uso destinado.",
  },
  {
    Question: "¿Cuándo me van a requerir demostrar el cumplimiento con la NOM?",
    Answer:
      "El cumplimiento puede requerirse cuando el producto es importado hacia México, o cuando es comercializado o distribuido en México o bien cuando es vendido en México. En algunos casos más se requiere el cumplimiento cuando el producto es operado en México.",
  },
  {
    Question:
      "¿Cumplir con la certificación NOM es un requisito para importar hacia México?",
    Answer:
      "En la mayoría de los casos sí es un requisito para importar hacia México, el cumplimiento en el punto de entrada al país está determinado por la fracción arancelaria que se asigna al producto para su importación. La fracción arancelaria está relacionada con las normas y/o regulaciones que se deben demostrar, es importante considerar que el producto debe cumplir antes de que sean introducidos hacia el territorio de Mexicano.",
  },
  {
    Question:
      "¿Cómo se demuestra que el producto cumple con las Normas Oficiales Mexicanas?",
    Answer:
      "El medio para demostrar que el producto cumple es obtener el certificado de cumplimiento NOM, mismo que es emitido por un Organismo de Certificación Acreditado y Aprobado para ello. Si la norma no es certificable pero verificable, el documento a obtener es una constancia o dictamen de cumplimiento.",
  },
  {
    Question:
      "¿Cuáles son los pasos que debo seguir para obtener el certificado de cumplimiento?",
    Answer:
      "El proceso de certificación depende de la NOM aplicable y del esquema de certificación que sea seleccionado, misma que proporciona todos los requisitos necesarios entre ellos documentos, proceso de muestreo, proceso de pruebas de laboratorio, proceso de certificación de producto y proceso de seguimiento a la certificación inicial.",
  },
  {
    Question: "¿Cuánto tiempo tarda completar el proceso de certificación?",
    Answer:
      "El tiempo para completar el proceso depende de la NOM aplicable y del esquema de certificación seleccionado; también influye que tipo de producto es el que se quiere certificar; uno de los tiempos más considerables es el de pruebas de laboratorio ya que realizar todas las pruebas aplicables puede tomar un tiempo considerable. También es determinante el tiempo que el cliente tarda para entregar toda la documentación técnica del producto que se utilizará en el proceso de pruebas y certificación.",
  },
  {
    Question:
      "¿Se requieren muestras del producto para completar el proceso de certificación?",
    Answer:
      "Sí, la norma NOM aplicable o la norma particular del producto determinan el número de especímenes de pruebas (número de muestras) sobre las cuales se deben aplicar los métodos de prueba de la norma.",
  },
  {
    Question:
      "¿Sí el producto ya está certificado en otros países puedo exentar el cumplimiento en México?",
    Answer:
      "No, si el producto ya se encuentra certificado con alguna marca de otro país, es muy probable que cumpla en México, pero México cuenta con un esquema de evaluación oficial que debe cumplirse.",
  },
  {
    Question: "¿Cómo me puede ayudar TSR de México?",
    Answer:
      "Le ayudamos a cumplir, podemos guiarle durante todo el proceso de certificación e incluso antes, planeando una estrategia adecuada a sus necesidades para obtener los certificados de las líneas de producto en el tiempo adecuado y en la forma correcta.      Trabajamos con la mayoría de los laboratorios de pruebas acreditados y aprobados para la realización de las pruebas en el menor tiempo posible.      Trabajamos con los principales organismos de certificación de México para obtener en el mejor tiempo los certificados de cumplimiento.      Le apoyamos con los procesos de clasificación arancelaria, determinación de la(s) normas aplicables a sus productos.      Realizamos todo el proceso de gestión ante los laboratorios de pruebas y organismos de certificación, siempre bajo la supervisión del cliente.      Nos hacemos cargo de sus certificaciones para que éstas no expiren y siempre se encuentren sus productos en cumplimiento con las Normas Oficiales Mexicanas aplicables.      Apoyamos en actividades adicionales que se relacionan con el proceso de certificación, como son traducciones de documentos, representación ante autoridades, pre-certificación; entre otras más.",
  },
];
