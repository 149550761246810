import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { ExtensionRounded, MenuBook } from "@material-ui/icons";
import "../../pages/Style.scss";
import Shadow from "../assets/EDITABLES/Shadow.png";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    maxHeight: 300,
    overflowY: "auto",
  },
  
}));

export default function GlobalServices(props) {
  const { Data } = props;
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <Box style={{ width: "100%" }}>
          <Box
            style={{
              width: "60%",
              marginLeft: "15%",
              textAlign: "center",
              marginTop: "50px",
            }}
          >
            <Typography
              variant="h4"
              className="Fonts Titles"
              style={{ color: "#999933" }}
            >
              {Data.Title}
            </Typography>
            <br />
            <Typography
              paragraph
              className="Fonts Text"
              style={{ fontWeight: "bold" }}
            >
              {Data.SubTitle}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Box className={classes.root}>
          {Data.Points
            ? Data.Points.map((Point, index) => (
                <Box
                  style={{
                    width: "90%",
                    margin: "5%",
                    height: "auto",
                    textAlign:"center"
                  }}
                >
                  
                  <Typography
                        className="Fonts Text"
                        style={{
                          width: "80%",
                          height: "auto",
                          textAlign: "justify",
                          display: "inline-block",
                          verticalAlign: "middle",
                          wordBreak: "break-word",
                          fontSize: "16px",
                          fontWeight: "600",
                          height: "auto",
                        }}
                        paragraph
                        className="Fonts Text"
                      >
                        {Point.Text}
                      </Typography>
                    <img src={Shadow} alt="" style={{width:"100%", height:"20px"}}/>
                </Box>
              ))
            : null}
        </Box>
      </Grid>
    </Grid>
  );
}
