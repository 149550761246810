import React, { useEffect, useState } from 'react';
import About from './pages/about';
import Contact from './pages/Contact';
import Faqs from './pages/Faqs';
import Home from './pages/Home';
import Services from './pages/Services';


function App() {
  const [Pages, setPages] = useState(0);
  const [ActiveServices, setActiveServices] = useState(null);
  const ChangePage = (page) => {
    setPages(page);
  }
  const goToServices = (num) => {
    setActiveServices(num);
  }
  useEffect(() => {
    if (ActiveServices !== null) {
      setPages(2)
    }
  }, [ActiveServices]);
  const pages = {
    0: <Home ChangePage={ChangePage} ActiveServices={goToServices} />,
    1: <About ChangePage={ChangePage} ActiveServices={goToServices} />,
    2: <Services ChangePage={ChangePage} ActiveServices={goToServices} ActServ={ActiveServices}/>,
    3: <Contact ChangePage={ChangePage} ActiveServices={goToServices} />,
    4: <Faqs ChangePage={ChangePage} ActiveServices={goToServices} />,
  }
  return (
    <>
      {pages[Pages]}
    </>
  );
}

export default App;
