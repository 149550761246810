import React, { useState } from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Grid,
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  IconButton,
  Box,
} from "@material-ui/core";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import HeaderImage from "../assets/BANNERS/Header.png";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "../assets/EDITABLES/TSRLogo.png";
import Julius from "../ChatBot/Julius";
function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundImage: `url(${HeaderImage})`,
    backgroundPosition: 'center', 
    backgroundSize: '100% 125px', 
    backgroundRepeat: 'no-repeat',
    // margin: 'auto',
    width: '100%',
    display: 'flex',
    height:"125px",    
  },
  toolbarMargin: {
    ...theme.mixins.toolbar,
    marginBottom: `5em`,
    [theme.breakpoints.down("md")]: {
      marginBottom: "4em",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: "2em",
    },
  },
  logo: {
    color: theme.palette.secondary.main,
    width: "max-content",
    fontSize: "1.5rem",
  },
  drawerIconContainer: {
    marginLeft: "auto",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  drawerIcon: {
    height: `50px`,
    width: `50px`,
    color: `#fff`,
    [theme.breakpoints.down("xs")]: {
      height: `40px`,
      width: `40px`,
    },
    marginRight:"32px",
  },
  drawer: {
    backgroundColor: "rgb(14,42,61)",
    color:"#999933",
    padding: "0 2em",
  },
  link: {
    fontSize: "1.25em",
    color: "white",
    "&:hover": {
      color: "#999933",
    },
    cursor:"pointer",
  },
}));

const Header = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [openDrawer, setOpenDrawer] = useState(false);
  const ChangePage=(page)=>{
    props.ChangePage(page);
  }
  const ActiveServices=(Service)=>{
    props.ActiveServices(Service);
    }
  const tabs = (
    <Grid container justify="flex-end" spacing={4} style={{marginRight:"16px",marginBottom:"-75px"}}>
      <Grid item >
        <Typography className={"Fonts Menu"} onClick={ChangePage.bind(this,2)}>Servicios</Typography>
      </Grid>
      <Grid item >
        <Typography className={"Fonts Menu"} onClick={ChangePage.bind(this,1)}>Nosotros</Typography>
      </Grid>
      <Grid item >
        <Typography className={"Fonts Menu"} onClick={ChangePage.bind(this,4)}>Preguntas Frecuentes</Typography>
      </Grid>
      {/* <Grid item >
        <Typography className={"Fonts Menu"}>Descargas</Typography>
      </Grid> */}
    </Grid>
  );
  const drawer = (
    <>
      <SwipeableDrawer
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{ paper: classes.drawer }}
        anchor="right"
      >
        <div className={classes.toolbarMargin} />
        <List disablePadding>
          <ListItem
            divider
            button
            onClick={ChangePage.bind(this,2)}
          >
            <ListItemText disableTypography>
              <Typography  className={"Fonts Menu"} variant="button">Servicios</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            divider
            button
            onClick={ChangePage.bind(this,1)}
          >
            <ListItemText disableTypography>
              <Typography  className={"Fonts Menu"} variant="button">Nosotros</Typography>
            </ListItemText>
          </ListItem>
          <ListItem
            divider
            button
            onClick={ChangePage.bind(this,4)}
          >
            <ListItemText disableTypography>
              <Typography  className={"Fonts Menu"} variant="button">Preguntas Frecuentes</Typography>
            </ListItemText>
          </ListItem>
          {/* <ListItem
            divider
            button
            onClick={() => {
              setOpenDrawer(false);
            }}
          >
            <ListItemText disableTypography>
              <Typography  className={"Fonts Menu"} variant="button">Descargas</Typography>
            </ListItemText>
          </ListItem> */}
        </List>
      </SwipeableDrawer>
      <IconButton
        onClick={() => setOpenDrawer(!openDrawer)}
        disableRipple
        className={classes.drawerIconContainer}
      >
        <MenuIcon className={classes.drawerIcon} />
      </IconButton>
    </>
  );
  return (
    <>
      <ElevationScroll>
        <AppBar className={classes.appBar} >
          <Toolbar
            disableGutters
          >
            <IconButton edge="start" onClick={ChangePage.bind(this,0)}>
            <img alt="IconTRS" src={Logo} style={{width:"90px",marginLeft:"32px", marginTop:"-2px"}}/>
            </IconButton>
            
            {matches ? drawer : tabs}
          </Toolbar>
          <Box className={classes.appBar2}/>
          <Julius ChangePage={ChangePage} ActiveServices={ActiveServices}/>
        </AppBar>
        
      </ElevationScroll>

      <div className={classes.toolbarMargin} />
    </>
  );
};
export default Header;
